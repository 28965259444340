import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useHeader } from "contexts/header"
import { CallToAction, HouseSelector, SEO } from "components"
import { usePreviewData } from "utils"

function QuickPossessionsPage() {
  const data = useStaticQuery(
    graphql`
      {
        prismicMoveInReady {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            opening_description {
              html
            }
            closing_block_image {
              url
              alt
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            closing_block_video {
              url
            }
            closing_block_title {
              text
            }
            closing_block_description {
              html
            }
            closing_block_button_text
            closing_block_button_link {
              link_type
              url
            }
            closing_block_style
            closing_block_pattern
            closing_block_color
            closing_block_side
          }
        }
      }
    `
  )
  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicMoveInReady } = MERGED_DATA

  const { setHeaderMode } = useHeader()

  useEffect(() => {
    setHeaderMode("solid")
  }, [])

  return (
    <>
      <SEO
        title={prismicMoveInReady.data.seo_title}
        description={prismicMoveInReady.data.seo_description}
        image={prismicMoveInReady.data.seo_image}
      />
      <HouseSelector
        full={true}
        subtext={prismicMoveInReady.data.opening_description}
      />
      <CallToAction
        image={prismicMoveInReady.data.closing_block_image}
        video={prismicMoveInReady.data.closing_block_video}
        heading={prismicMoveInReady.data.closing_block_title}
        text={prismicMoveInReady.data.closing_block_description}
        button={prismicMoveInReady.data.closing_block_button_text}
        link={prismicMoveInReady.data.closing_block_button_link}
        style={prismicMoveInReady.data.closing_block_style}
        pattern={prismicMoveInReady.data.closing_block_pattern}
        bg={prismicMoveInReady.data.closing_block_color}
        align={prismicMoveInReady.data.closing_block_side}
      />
    </>
  )
}

export default QuickPossessionsPage
